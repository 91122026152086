// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-jsx": () => import("/opt/build/repo/src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-portfolio-item-jsx": () => import("/opt/build/repo/src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */),
  "component---src-templates-tags-jsx": () => import("/opt/build/repo/src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-tags-index-jsx": () => import("/opt/build/repo/src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */)
}

